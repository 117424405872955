<template>
    <div class="warrantyWithout">
        <ConponentHeader></ConponentHeader>

        <div class="container">
            <div class="title">
                Extend Your Warranty For 12 Months.
            </div>
            <div class="form">
                <p>
                    <input type="text" placeholder="Name">
                </p>
                <p>
                    <input type="text" placeholder="Email">
                </p>
            </div>
            <div class="upload">
                <h3 class="upload-title">
                    Please upload an image of the front and back of your product packaging.
                </h3>
                <div class="upload-btn">
                    <input type="file" style="display:none" id="upload-file">
                    <img src="../assets/images/62a0677.jpg" alt="">
                    <label for="upload-file">
                        <img src="../assets/images/7db595e.png" alt="">
                    </label>

                </div>
                <div class="upload-agree">
                    <span class="conditions">
                        Terms & conditions 
                        <div class="conditions-text">
                            <ul>
                                <li>We will not share your personal information with any third parties.</li>
                                <li>Your purchase must be from a Letsfit authorized Amazon seller, or bought from Letsfit Official Amazon store.</li>
                                <li>We reserve the right to refuse for any stated reason upon direct request.</li>
                            </ul>
                        </div>
                    </span>
                    <span class="checkbox-box" @click="checkBox">
                        <span class="checkbox" >
                            <span v-if="check"  class="icon iconfont">&#xe62f;</span>
                        </span>
                        <span>I agree</span>
                    </span>
                </div>

                <div class="upload-btn">
                    <button type="button">Submit</button>
                </div>

                <div class="upload-here">Click  <router-link to="/warrantyExplain">here</router-link>  to view our Warranty Policy</div>
            </div>
        </div>

        <ConponentFooter></ConponentFooter>

    </div>
</template>

<script>
import ConponentHeader from '../components/header'
import ConponentFooter from '../components/footer'
export default {
data(){
    return{
     check:false 
    }
},
methods:{
    checkBox(){
        this.check = !this.check
    }
},
components:{
        ConponentHeader,
        ConponentFooter
   }   
}
</script>

<style lang="scss" scoped>
 .warrantyWithout{
     .container{
         text-align: center;
     }
     padding-top: 70px;
     .title{
       font-size: 42px;
        font-weight: 400;
        margin-top: 100px;
        margin-bottom: 100px;
        text-align: center;
        color: #222;
     }
     .form{
         text-align: center;
         p{
             input{
                 width: 470px;
                margin-top: 10px;
                padding: 10px;
                border: none;
                border-bottom: 1px solid #222;
                font-size: 22px;
                outline: none;
             }
         }
     }
     .upload{
             margin-top: 40px;  
         .upload-title{
                 font-size: 24px;
                 font-weight: 200;
         }
         .upload-btn{
             margin-top: 20px;
             img{
                 width: 184px;
                 height: 184px;
                 margin: 0 15px;
             }
         }
          .upload-btn{
                  margin-top: 60px;
            button{
                border: none;
                font-size: 26px;
                color: #fff;
                height: 55px;
                padding-left: 46px;
                padding-right: 46px;
                background-color: #89c14c;
                border-radius: 50px;
                cursor: pointer;
                outline: none;
            }

        }
        .upload-here{
            margin: 60px 0;
            a{
                text-decoration: none;
                color: #89c14c;
            }
        }
     }
     .upload-agree{
         margin-top: 15px;
        font-size: 20px;
        cursor: pointer;
        font-weight: 200;
        display: flex;
        justify-content: center;
        .conditions{
            position: relative;
            &:hover .conditions-text{
                display: block;
            }
            .conditions-text{
                position: absolute;
                display: none;
                left: -47px;
                top: 31px;
                ul{
                    
                    width: 523px;
                    text-align: left;
                    background-color: #e9e9e9;
                    list-style-type: disc;
                    padding: 20px;
                    list-style-position: outside;
                    li{
                        font-size: 16px;
                        color: #222;
                        line-height: 1.5;
                        margin-left: 20px;
                    }
                }
            }
        }
        .checkbox-box{
            display: flex;
            line-height: 15px;
            text-align: center;
        }
        .checkbox{
            width: 20px;
            height: 20px;
            display: inline-block;
            border: 1px solid #999;
            margin-left: 70px;
            margin-right: 20px;
        }
       
     }
 }   
</style>