<template>
    <div class="search">
        <ConponentHeader></ConponentHeader>
        <div class="container">
            <div class="product-search">
                <input type="text">
                <img src="../assets/images/9a8012e.png" alt="">
            </div>

            <div class="no-result">
                <img src="../assets/images/d46f036.png" alt="">

                <div class="no-result-text">
                    Oops! Looks like there is no result fount. <br>
                        Click to browse all of our <router-link to="/productList">products</router-link>
                </div>
            </div>
        </div>
         <ConponentFooter></ConponentFooter> 
    </div>
</template>

<script>
import ConponentHeader from '../components/header'
import ConponentFooter from '../components/footer'
export default {
    components:{
        ConponentHeader,
        ConponentFooter
    }  
}
</script>
<style lang="scss" scoped>
.search{
    padding-top: 70px;
    .container{
        .product-search{
            width: 600px;
            margin: 46px auto 42px;
            border: 1px solid #e2e2e2;
            display: flex;
            justify-content: space-between;
            input{
                height: 30px;
                border: none;
                width: 480px;
                font-size: 18px;
                padding-left: 10px;
                outline: none;
            }
            img{
                width: 32px;
                height: 32px;
            }
        }
        .no-result{
            height: 500px;
            text-align: center;
            img{
                width: 143px;
                height: 143px;
            }
            .no-result-text{
                font-size: 18px;
                color: #666;
                margin-bottom: 240px;
                a{
                    cursor: pointer;
                    color: #89c14c;
                    text-decoration: none;
                }
            }
        }
    }
}
</style>