<template>
    <div class="warrantyExplain">
        <ConponentHeader></ConponentHeader>
        <div class="container">
            <div class="div_title">
                More than just a warranty.
            </div>
            <p class="div_p_text">Letsfit provides a 1-year hassle-free warranty for all out products.</p>
            <p class="div_p_text">This limited warranty provided by the manufacturer in no way affects any potential statutory warranty provided by law.</p>
            
            <div class="container-div" v-for="(item,index) in WarrantyData" :key="index">
               <div class="container-div-title" @click="showContent(index)"  :class="{'selsect':item.state}">
                   <span>{{item.name}}</span>
                   <span class="icon iconfont">&#xe638;</span>
               </div>
               <div :class="{'container-div-content':true,'showContent':item.state}" v-html="item.content"></div>
            </div>

            <div class="div-btn">
              <router-link to="/warranty">
                  <div class="btn">Extend your warranty</div>
                </router-link>  
            </div>
        </div>
        

        <ConponentFooter></ConponentFooter>
    </div>
</template>
<script>
import ConponentHeader from '../components/header'
import ConponentFooter from '../components/footer'
export default {
   data(){
       return{
           WarrantyData:[
               {
                   state:false,
                   name:'30-Day Money-Back Guarantee',
                   content:`<p class="text" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; box-sizing: border-box; border: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; font-size: 20px; line-height: inherit; font-family: lettering, Nunito; vertical-align: baseline; cursor: inherit; white-space: normal;">Undamaged products may be returned for a full refund for any reason within 30 days of the date the item was delivered to the designated shipping address.<br/><br/>Once the returned item arrives back in our warehouse for inspection, the refund process will begin.<br/><br/>Please note:<br/><br/>● Returns must include all accessories<br/>● Items must include original packaging<br/>● For non-quality related warranty claims, buyer is responsible for shipping costs<br/>● For non-quality related warranty claims, Letsfit refunds the cost of the product itself<br/>● Returns may be rejected if items do not meet the above requirements<br/><br/></p><p class="text" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; box-sizing: border-box; border: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; font-size: 20px; line-height: inherit; font-family: lettering, Nunito; vertical-align: baseline; cursor: inherit; white-space: normal;">Refund requests for the 30-day money back guarantee expire 30 days after Refund requests for the 30-day money back guarantee expire 30 days after for non-quality issues for items that have expired this 30-day window. For purchases not made directly through our online stores, please contact retailers for refunds. For quality-related issues, please see below.</p><p><br/></p>`
               },
               {
                   state:false,
                   name:'30-Day Money-Back Guarantee',
                   content:`<p class="text" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; box-sizing: border-box; border: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; font-size: 20px; line-height: inherit; font-family: lettering, Nunito; vertical-align: baseline; cursor: inherit; white-space: normal;">Undamaged products may be returned for a full refund for any reason within 30 days of the date the item was delivered to the designated shipping address.<br/><br/>Once the returned item arrives back in our warehouse for inspection, the refund process will begin.<br/><br/>Please note:<br/><br/>● Returns must include all accessories<br/>● Items must include original packaging<br/>● For non-quality related warranty claims, buyer is responsible for shipping costs<br/>● For non-quality related warranty claims, Letsfit refunds the cost of the product itself<br/>● Returns may be rejected if items do not meet the above requirements<br/><br/></p><p class="text" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; box-sizing: border-box; border: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; font-size: 20px; line-height: inherit; font-family: lettering, Nunito; vertical-align: baseline; cursor: inherit; white-space: normal;">Refund requests for the 30-day money back guarantee expire 30 days after Refund requests for the 30-day money back guarantee expire 30 days after for non-quality issues for items that have expired this 30-day window. For purchases not made directly through our online stores, please contact retailers for refunds. For quality-related issues, please see below.</p><p><br/></p>`
               },
               {
                   state:false,
                   name:'30-Day Money-Back Guarantee',
                   content:`<p class="text" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; box-sizing: border-box; border: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; font-size: 20px; line-height: inherit; font-family: lettering, Nunito; vertical-align: baseline; cursor: inherit; white-space: normal;">Undamaged products may be returned for a full refund for any reason within 30 days of the date the item was delivered to the designated shipping address.<br/><br/>Once the returned item arrives back in our warehouse for inspection, the refund process will begin.<br/><br/>Please note:<br/><br/>● Returns must include all accessories<br/>● Items must include original packaging<br/>● For non-quality related warranty claims, buyer is responsible for shipping costs<br/>● For non-quality related warranty claims, Letsfit refunds the cost of the product itself<br/>● Returns may be rejected if items do not meet the above requirements<br/><br/></p><p class="text" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; box-sizing: border-box; border: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; font-size: 20px; line-height: inherit; font-family: lettering, Nunito; vertical-align: baseline; cursor: inherit; white-space: normal;">Refund requests for the 30-day money back guarantee expire 30 days after Refund requests for the 30-day money back guarantee expire 30 days after for non-quality issues for items that have expired this 30-day window. For purchases not made directly through our online stores, please contact retailers for refunds. For quality-related issues, please see below.</p><p><br/></p>`
               },
               {
                   state:false,
                   name:'30-Day Money-Back Guarantee',
                   content:`<p class="text" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; box-sizing: border-box; border: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; font-size: 20px; line-height: inherit; font-family: lettering, Nunito; vertical-align: baseline; cursor: inherit; white-space: normal;">Undamaged products may be returned for a full refund for any reason within 30 days of the date the item was delivered to the designated shipping address.<br/><br/>Once the returned item arrives back in our warehouse for inspection, the refund process will begin.<br/><br/>Please note:<br/><br/>● Returns must include all accessories<br/>● Items must include original packaging<br/>● For non-quality related warranty claims, buyer is responsible for shipping costs<br/>● For non-quality related warranty claims, Letsfit refunds the cost of the product itself<br/>● Returns may be rejected if items do not meet the above requirements<br/><br/></p><p class="text" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; box-sizing: border-box; border: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; font-size: 20px; line-height: inherit; font-family: lettering, Nunito; vertical-align: baseline; cursor: inherit; white-space: normal;">Refund requests for the 30-day money back guarantee expire 30 days after Refund requests for the 30-day money back guarantee expire 30 days after for non-quality issues for items that have expired this 30-day window. For purchases not made directly through our online stores, please contact retailers for refunds. For quality-related issues, please see below.</p><p><br/></p>`
               }
           ]
       }
   },
   methods:{
       showContent(idx){
           for(var i=0;i<this.WarrantyData.length;i++){
               if(i == idx) continue
                this.WarrantyData[i].state = false
           }
           this.WarrantyData[idx].state = !this.WarrantyData[idx].state 

       }
   },
   components:{
        ConponentHeader,
        ConponentFooter
   } 
}
</script>

<style lang="scss" scoped>
 .warrantyExplain{
         padding-top: 70px;
     .container{
         .div_title{
             font-weight: 200;
            margin-top: 90px;
            font-size: 46px;
            text-align: center;
            padding-bottom: 60px;
         }
         .div_p_text{
             padding-left: 10px;
            font-size: 18px;
            color: #666;
            padding-bottom: 10px;
            font-weight: 200;
         }

         .container-div-content{
             box-sizing: border-box;
            padding: 0px 0 0px 10px;
            font-size: 16px;
            line-height: 1.3;
            max-height: 0;
            overflow: hidden;
            transition: all .5s;
            background-color: #f0ffe2;
         }
         .showContent{
             max-height: 1000px;

         }
         
         .container-div-title   {
             padding:0  10px;
             display: flex;
            box-sizing: border-box;
             justify-content: space-between;
            width: 100%;
            height: 60px;
            line-height: 60px;
            font-size: 20px;
            border-top: 1px solid #e1e1e1;
            border-bottom: 1px solid #e1e1e1;
            cursor: pointer;
            .icon{
                font-size: 26px;
                color: #ccc;
            }
         }
         .selsect{
             background-color: #80ca32;
             color: #fff;
             .icon{
                 transform: rotate(180deg);
             }
         }
     }
     .div-btn{
         text-align: center;
         a{
             text-decoration: none;
         }
         .btn{
             width: 220px;
            height: 55px;
            border: 1px solid #80ca32;
            font-size: 18px;
            color: #fff;
            text-align: center;
            line-height: 55px;
            background-color: #80ca32;
            border-radius: 30px;
            margin: 40px auto;
         }
     }
 }   
</style>