<template>
    <div class="productList">
        <ConponentHeader></ConponentHeader>
        <div class="container">
            <div class="product-h">
                Find a product that's fit for you.
            </div>
            <div class="product-div">
                <div class="product-s-img">
                    <router-link to="/FitnessWearables">
                        <img src="../assets/images/de1111.jpg" alt="">
                    </router-link>
                </div>
                <div class="product-s-img">
                    <router-link to="/FitnessWearables">
                        <img src="../assets/images/de1111.jpg" alt="">
                    </router-link>
                </div>
                <div class="product-s-img">
                    <router-link to="/FitnessWearables">
                        <img src="../assets/images/de1111.jpg" alt="">
                    </router-link>
                </div>
                <div class="product-s-img">
                    <router-link to="/FitnessWearables">
                        <img src="../assets/images/de1111.jpg" alt="">
                    </router-link>
                </div>
                <div class="product-s-img">
                    <router-link to="/FitnessWearables">
                        <img src="../assets/images/de1111.jpg" alt="">
                    </router-link>
                </div>
                <div class="product-s-img">
                    <router-link to="/FitnessWearables">
                        <img src="../assets/images/de1111.jpg" alt="">
                    </router-link>
                </div>
                <div class="product-s-img">
                    <router-link to="/FitnessWearables">
                        <img src="../assets/images/de1111.jpg" alt="">
                    </router-link>
                </div>
           
            </div>
        </div>
        <ConponentFooter></ConponentFooter> 
    </div>
</template>
<script>
import ConponentHeader from '../components/header'
import ConponentFooter from '../components/footer'
export default {
    components:{
        ConponentHeader,
        ConponentFooter
    } 
}
</script>
<style lang="scss" scoped>
.productList{
    padding-top: 70px;
    .product-h{
        text-align: center;
        font-size: 26px;
        color: #595959;
        text-align: center;
        margin: 50px 0;
    }
    .product-div{
        width: 1070px;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .product-s-img{
            width: 526px;
            height: 255px;
            border-radius: 5px;
            margin-bottom: 22px;
            cursor: pointer;
            img{
                width: 100%;
                height: 100%;
                border-radius: 5px;
                transform: scale(.98);
                transition: all .5s;
                &:hover{
                    transform: scale(1.01);
                }
            }
        }
    }
}
</style>