<template>
	<div class="Community">
		<ConponentHeader></ConponentHeader>
		<div class="Community-main">
			<div class="Community-box">
				<div class="Community-tab">
					<span :class="{Tabcheck:current == 0}" @click="switchTab(0)">Press</span>
					<span :class="{Tabcheck:current == 1}" @click="switchTab(1)">Events</span>
					<span :class="{Tabcheck:current == 2}" @click="switchTab(2)">Testing Club</span>
				</div>
			</div>
		</div>
		
		<div :class="{'Community-tab-item':true,'showTab':current == 0}">
			<div class="Press">
				<div class="Press-box">
					<div class="Press-box-item">
						<div class="readMore">
							<a href="#" class="readMore-btn">Read more</a>
						</div>
						<div class="Press-left">
							<img src="../assets/images/tx.jpg" alt="">
						</div>
						<div class="Press-right">
							<div class="Press-right-title">Letsfit Unveils $40 Smartwatch with 
							Pulse Oximeter Capabilities at CES</div>
							<div class="Press-right-time">26th Jan 2021</div>
							<div class="Press-right-text">Additional health and fitness products released inc                              lude a new app, a touchscreen white noise machine, and non-slip earbuds.
							</div>
						</div>
					</div>
					<div class="Press-box-item">
						<div class="readMore">
							<a href="#" class="readMore-btn">Read more</a>
						</div>
						<div class="Press-left">
							<img src="../assets/images/tx.jpg" alt="">
						</div>
						<div class="Press-right">
							<div class="Press-right-title">Letsfit Unveils $40 Smartwatch with 
							Pulse Oximeter Capabilities at CES</div>
							<div class="Press-right-time">26th Jan 2021</div>
							<div class="Press-right-text">Additional health and fitness products released inc                              lude a new app, a touchscreen white noise machine, and non-slip earbuds.
							</div>
						</div>
					</div>
					<div class="Press-box-item Press-box-img">
						<div class="readMore">
							<a href="#" class="readMore-btn">Read more</a>
						</div>
						<div class="Press-box-img">
							<img src="../assets/images/sds.jpg" alt="">
						</div>
						<div class="Press-box-item-img">
							<div class="Press-left">
								<img src="../assets/images/tx.jpg" alt="">
							</div>
							<div class="Press-right">
								<div class="Press-right-title">Letsfit Unveils $40 Smartwatch with 
								Pulse Oximeter Capabilities at CES</div>
								<div class="Press-right-time">26th Jan 2021</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="Press">
				<div class="Press-box">
					<div class="Press-box-item">
						<div class="readMore">
							<a href="#" class="readMore-btn">Read more</a>
						</div>
						<div class="Press-left">
							<img src="../assets/images/tx.jpg" alt="">
						</div>
						<div class="Press-right">
							<div class="Press-right-title">Letsfit Unveils $40 Smartwatch with 
							Pulse Oximeter Capabilities at CES</div>
							<div class="Press-right-time">26th Jan 2021</div>
							<div class="Press-right-text">Additional health and fitness products released inc                              lude a new app, a touchscreen white noise machine, and non-slip earbuds.
							</div>
						</div>
					</div>
					<div class="Press-box-item">
						<div class="readMore">
							<a href="#" class="readMore-btn">Read more</a>
						</div>
						<div class="Press-left">
							<img src="../assets/images/tx.jpg" alt="">
						</div>
						<div class="Press-right">
							<div class="Press-right-title">Letsfit Unveils $40 Smartwatch with 
							Pulse Oximeter Capabilities at CES</div>
							<div class="Press-right-time">26th Jan 2021</div>
							<div class="Press-right-text">Additional health and fitness products released inc                              , and non-slip earbuds.
							</div>
						</div>
					</div>
					<div class="Press-box-item Press-box-img">
						<div class="readMore">
							<a href="#" class="readMore-btn">Read more</a>
						</div>
						<div class="Press-box-img">
							<img src="../assets/images/sds.jpg" alt="">
						</div>
						<div class="Press-box-item-img">
							<div class="Press-left">
								<img src="../assets/images/tx.jpg" alt="">
							</div>
							<div class="Press-right">
								<div class="Press-right-title">Letsfit Unveils $40 Smartwatch with 
								Pulse Oximeter Capabilities at CES</div>
								<div class="Press-right-time">26th Jan 2021</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="Press">
				<div class="Press-box">
					<div class="Press-box-item">
						<div class="readMore">
							<a href="#" class="readMore-btn">Read more</a>
						</div>
						<div class="Press-left">
							<img src="../assets/images/tx.jpg" alt="">
						</div>
						<div class="Press-right">
							<div class="Press-right-title">Letsfit Unveils $40 Smartwatch with 
							Pulse Oximeter Capabilities at CES</div>
							<div class="Press-right-time">26th Jan 2021</div>
							<div class="Press-right-text">Additional health and fitness products released inc                              lude a new app, a touchscreen white noise machine, and non-slip earbuds.
							</div>
						</div>
					</div>
					<div class="Press-box-item">
						<div class="readMore">
							<a href="#" class="readMore-btn">Read more</a>
						</div>
						<div class="Press-left">
							<img src="../assets/images/tx.jpg" alt="">
						</div>
						<div class="Press-right">
							<div class="Press-right-title">Letsfit Unveils $40 Smartwatch with 
							Pulse Oximeter Capabilities at CES</div>
							<div class="Press-right-time">26th Jan 2021</div>
							<div class="Press-right-text">Additional health and fitness products released inc                              lude a new app, a touchscreen white noise machine, and non-slip earbuds.
							</div>
						</div>
					</div>
					<div class="Press-box-item Press-box-img">
						<div class="readMore">
							<a href="#" class="readMore-btn">Read more</a>
						</div>
						<div class="Press-box-img">
							<img src="../assets/images/sds.jpg" alt="">
						</div>
						<div class="Press-box-item-img">
							<div class="Press-left">
								<img src="../assets/images/tx.jpg" alt="">
							</div>
							<div class="Press-right">
								<div class="Press-right-title">Letsfit Unveils $40 Smartwatch with 
								Pulse Oximeter Capabilities at CES</div>
								<div class="Press-right-time">26th Jan 2021</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			
			
			
		</div>
		
		<div :class="{'Community-tab-item':true,'showTab':current == 1}">
			<div class="Press">
				<div class="Press-box">
	
					<div class="Press-box-item Press-box-img">
						<div class="readMore">
							<a href="#" class="readMore-btn">Read more</a>
						</div>
						<div class="Press-box-img">
							<img src="../assets/images/sds.jpg" alt="">
						</div>
						<div class="Press-box-item-img">
							<div class="Press-left">
								<img src="../assets/images/tx.jpg" alt="">
							</div>
							<div class="Press-right">
								<div class="Press-right-title">Letsfit Unveils $40 Smartwatch with 
								Pulse Oximeter Capabilities at CES</div>
								<div class="Press-right-time">26th Jan 2021</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="Press">
				<div class="Press-box">

					<div class="Press-box-item Press-box-img">
						<div class="readMore">
							<a href="#" class="readMore-btn">Read more</a>
						</div>
						<div class="Press-box-img">
							<img src="../assets/images/sds.jpg" alt="">
						</div>
						<div class="Press-box-item-img">
							<div class="Press-left">
								<img src="../assets/images/tx.jpg" alt="">
							</div>
							<div class="Press-right">
								<div class="Press-right-title">Letsfit Unveils $40 Smartwatch with 
								Pulse Oximeter Capabilities at CES</div>
								<div class="Press-right-time">26th Jan 2021</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="Press">
				<div class="Press-box">

					<div class="Press-box-item Press-box-img">
						<div class="readMore">
							<a href="#" class="readMore-btn">Read more</a>
						</div>
						<div class="Press-box-img">
							<img src="../assets/images/sds.jpg" alt="">
						</div>
						<div class="Press-box-item-img">
							<div class="Press-left">
								<img src="../assets/images/tx.jpg" alt="">
							</div>
							<div class="Press-right">
								<div class="Press-right-title">Letsfit Unveils $40 Smartwatch with 
								Pulse Oximeter Capabilities at CES</div>
								<div class="Press-right-time">26th Jan 2021</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div  :class="{'Testing-tab':true,'clear':true,'ShowTesting':current == 2}">
			<p class="Testing-tab-title">Check out what other people are saying about our products.</p>
			<p class="Testing-tab-desc">Simply choose a category below to see reviews of different products and find out what our customers think.</p>
			<div class="Testing-List">
				<ul>
					<li>
						<div class="list-item-title">Fitness Wearables</div>
						<div class="list-item-text">Meet your new personal trainer</div>
						<img src="../assets/images/5717ebf.png" alt="">
					</li>
					<li>
						<div class="list-item-title">Fitness Wearables</div>
						<div class="list-item-text">Meet your new personal trainer</div>
						<img src="../assets/images/7a6f346.png" alt="">
					</li>
					<li>
						<div class="list-item-title">Fitness Wearables</div>
						<div class="list-item-text">Meet your new personal trainer</div>
						<img src="../assets/images/dccce5e.png" alt="">
					</li>
					<li>
						<div class="list-item-title">Fitness Wearables</div>
						<div class="list-item-text">Meet your new personal trainer</div>
						<img src="../assets/images/70cd17e.png" alt="">
					</li>
				</ul>
			</div>
			
		</div>
		<ConponentFooter></ConponentFooter>  
	</div>
</template>

<script>
import ConponentHeader from '../components/header'
import ConponentFooter from '../components/footer'
export default{
	data(){
		return{
			current:0
		}
	},
	methods:{
		switchTab(index){
			this.current = index
		}
	},
	components:{
	   ConponentHeader,
	   ConponentFooter
	}
}
</script>

<style scoped="scoped" lang="scss">
	.Community{
		background-color: #f7f7f7;
	}
	.Community-main{
		margin-top: 70px;
		.Community-box{
			width: 990px;
			margin: 0 auto;
			.Community-tab{
				text-align: center;
				// padding-top: 140px;
				height: 140px;
				line-height: 230px;
				padding-bottom: 10px;
				span{
					display: inline-block;
					width: 158px;
					margin: 0 20px;
					font-size: 26px;
					color: #666;
					white-space: nowrap;
					cursor: pointer;
					z-index: 1;
					position: relative;
					transition: all 1s;
					&::after{
						content: '.';
						display: inline-block;
						color: #89c14c;
						position: absolute;
						bottom: -20px;
						left: 0;
						right: 0;
						margin: auto;
						opacity: 0;
					}
				}
				.Tabcheck{
					transform: translateY(-15px);
					font-size: 32px;
					color: #89c14c;
					font-size: 32px;
				}
				.Tabcheck::after{
					opacity: 1;
				}
			}
		}
		
	}
	.showTab{
		display: flex!important;
	}
	.ShowTesting{
		display: block!important;
	}
	.Testing-tab{
		width: 1100px;
		margin: 0 auto;
		display: none;
		.Testing-tab-title{
			margin-top: 40px;
			font-size: 18px;
			color: #404040;
		}
		.Testing-tab-desc{
			margin-top: 10px;
			font-size: 14px;
			color: #999;
			line-height: 18px;
		}
		.Testing-List{
			li{
				list-style: none;
				float: left;
				width: 267px;
				height: 439px;
				background-color: #FFFFFF;
				overflow: hidden;
				position: relative;
				margin:0 3px;
				margin-top: 35px;
				border: 1px solid transparent;
				&:hover{
					border: 1px solid #72bb4a;
				}
				&:hover .list-item-title{
					color: #72bb4a;
				}
				&:hover img{
					left: 10px;
				}
				.list-item-title{
					font-size: 20px;
					font-weight: 700;
					margin-top: 43px;
					margin-left: 20px;
					font-weight: 400;
				}
				.list-item-text{
					padding: 15px 2px 0 20px;
					font-size: 18px;
					color: #999;
				}
				img{
					width: 300px;
					height: 250px;
					position: absolute;
					bottom: 10px;
					left: 34px;
					transition: all 1s;
				}
				
			}
		}
	}
	.Community-tab-item{
		width: 990px;
		margin: 0 auto;
		justify-content: space-between;
		background-color:#f7f7f7;
		display: none;
		.Press{
			width: 296px;
			// border: 1px solid red;
			.Press-box-img{
				display: block!important;
				padding: 0!important;
			}
			.Press-box-item-img{
				display: flex;
				border-radius: 15px;
				overflow: hidden;
				padding: 26px 10px;
				box-sizing: border-box;
				box-shadow: 0 0 10px 5px rgba(0,0,0,.1);
				position: relative;
				&:hover .readMore{
					display: block;
				}
			}
			.Press-box-item{
				display: flex;
				border-radius: 15px;
				overflow: hidden;
				padding: 26px 10px;
				box-sizing: border-box;
				box-shadow: 0 0 10px 5px rgba(0,0,0,.1);
				position: relative;
				margin: 25px 0;
				&:hover .readMore{
					display: block;
				}
				
				.readMore{
					position: absolute;
					width: 100%;
					height: 100%;
					background-color: rgba(0,0,0,.5);
					left: 0;
					top: 0;
					text-align: center;
					padding-top: 80px;
					cursor: pointer;
					display: none;
					.readMore-btn{
						padding: 8px 30px;
						font-size: 22px;
						color: #fff;
						border: 1px solid #fff;
						border-radius: 10px;
						cursor: pointer;
						margin-top: 50px;
						text-decoration: none;

					}
				}
				
			}
			.Press-left{
				width: 37px;
				height: 37px;
				border-radius: 50%;
				background-color: #c7caca;
				overflow: hidden;
				img{
					width: 37px;
					height: 37px;
				}
			}
			.Press-right{
				width: 229px;
				margin-left: 10px;
				// border: 1px solid red;
				.Press-right-title{
					font-size: 23px;
					color: #000;
					font-weight: 200;
				}
				.Press-right-time,
				.Press-right-text{
					font-size: 16px;
					margin-top: 10px;
					color: #666;
				}
			}
		}
	}
	
	
	
</style>
