<template>
    <div class="FitnessWearables">
        <ConponentHeader></ConponentHeader>
        <div class="swiper-box">
            <div class="swiper-item" v-for="(item,index) in bannerData" :key="index">
                <img :src="item"  style="display:none" :class="{'showBanner':current == index}">
            </div>    
        </div>    
        <div class="container">
            <div class="tabs_div2_header">
                <div class="tabs_div2_left">
                    <span :class="{'showTab':tab == 1}" @click="swiperTab(1)">Smart Watches</span>
                    <span  :class="{'showTab':tab == 2}"  @click="swiperTab(2)">Fitness Trackers</span>
                </div>
                <div class="tabs_div2_right">
                    <router-link to="/search">
                        <img src="../assets/images/ss.png" alt="">
                    </router-link>
                </div>
            </div>
            <div class="product_drop">
                <span>Sort By</span>
                <el-select v-model="value" placeholder="Select option">
                    <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div class="Listimg_div">
               <div class="Listimg_div-item">
                   <div class="Listimg_div-new">
                       <span>NEW</span>
                   </div>
                   <img src="../assets/images/p1.png" alt="">
                   <div class="Listimg_div-name">IW1</div>
                   <div class="Listimg_div-pic">$35.99</div>

                   <div class="Listimg_div-modeBox">
                       <div class="Listimg_div-modeBox-name">
                           IW1
                       </div>
                       <div class="Listimg_div-modeBox-pic">$ 37.00</div>
                       <div class="Listimg_div-modeBox-text">
                           Your fitness has never been easier to stay on top of now with our all new IW1
                       </div>
                       <div class="Listimg_div-modeBox-color">
                           <span style="background:red;"></span>
                           <span style="background:pink;"></span>
                           <span style="background:blue;"></span>
                       </div>
                   </div>
               </div>
               <div class="Listimg_div-item">
                   <div class="Listimg_div-new">
                       <span>NEW</span>
                   </div>
                   <img src="../assets/images/p1.png" alt="">
                   <div class="Listimg_div-name">IW1</div>
                   <div class="Listimg_div-pic">$35.99</div>

                   <div class="Listimg_div-modeBox">
                       <div class="Listimg_div-modeBox-name">
                           IW1
                       </div>
                       <div class="Listimg_div-modeBox-pic">$ 37.00</div>
                       <div class="Listimg_div-modeBox-text">
                           Your fitness has never been easier to stay on top of now with our all new IW1
                       </div>
                       <div class="Listimg_div-modeBox-color">
                           <span style="background:red;"></span>
                           <span style="background:pink;"></span>
                           <span style="background:blue;"></span>
                       </div>
                   </div>
               </div>
               <div class="Listimg_div-item">
                   <div class="Listimg_div-new">
                       <span>NEW</span>
                   </div>
                   <img src="../assets/images/p1.png" alt="">
                   <div class="Listimg_div-name">IW1</div>
                   <div class="Listimg_div-pic">$35.99</div>

                   <div class="Listimg_div-modeBox">
                       <div class="Listimg_div-modeBox-name">
                           IW1
                       </div>
                       <div class="Listimg_div-modeBox-pic">$ 37.00</div>
                       <div class="Listimg_div-modeBox-text">
                           Your fitness has never been easier to stay on top of now with our all new IW1
                       </div>
                       <div class="Listimg_div-modeBox-color">
                           <span style="background:red;"></span>
                           <span style="background:pink;"></span>
                           <span style="background:blue;"></span>
                       </div>
                   </div>
               </div>
               <div class="Listimg_div-item">
                   <div class="Listimg_div-new">
                       <span>NEW</span>
                   </div>
                   <img src="../assets/images/p1.png" alt="">
                   <div class="Listimg_div-name">IW1</div>
                   <div class="Listimg_div-pic">$35.99</div>

                   <div class="Listimg_div-modeBox">
                       <div class="Listimg_div-modeBox-name">
                           IW1
                       </div>
                       <div class="Listimg_div-modeBox-pic">$ 37.00</div>
                       <div class="Listimg_div-modeBox-text">
                           Your fitness has never been easier to stay on top of now with our all new IW1
                       </div>
                       <div class="Listimg_div-modeBox-color">
                           <span style="background:red;"></span>
                           <span style="background:pink;"></span>
                           <span style="background:blue;"></span>
                       </div>
                   </div>
               </div>
            </div>
        </div>
        <ConponentFooter></ConponentFooter>
    </div>
</template>

<script>
import ConponentHeader from '../components/header'
import ConponentFooter from '../components/footer'

export default {
    data(){
        return{
            bannerData:[
                require('../assets/images/baannr-03.jpg'),
                require('../assets/images/baannr-04.jpg')
            ],
            current:0,
            tab:1
        }
    },
    mounted(){
        // this.autoSwiper()
    },
    methods:{
        swiperTab(idx){
            this.tab = idx 
        },
        autoSwiper(){
            setInterval(()=>{
                var len = this.bannerData.length-1
                if(len == this.current) {
                    this.current = 0
                }else{
                     this.current++
                }

                

                console.log(this.current)
            },1000)
        }
    },
    components:{
        ConponentHeader,
        ConponentFooter,

    }   
}
</script>

<style lang="scss" scoped>
.FitnessWearables{
    padding-top: 70px;
    .swiper-box{
        .swiper-item{
            img{
                width: 100%;
            }
            .showBanner{
                display: block!important;
            }
        }
    }
    .container{
        .tabs_div2_header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 37px;
            margin-bottom: 37px;
            .tabs_div2_left{
                span{
                    display: inline-block;
                    height: 30px;
                    line-height: 30px;
                    font-size: 20px;
                    cursor: pointer;
                    font-weight: 200;
                    margin-right: 80px;
                    position: relative;
                }
                .showTab{
                    color: #89c14c; 
                      &::after{
                        content: '';
                        display: inline-block;
                        width: 100%;
                        height: 1px;
                        background-color: #89c14c;
                        position: absolute;
                        bottom: -5px;
                        left: 0;
                    }
                }
            }
            
        }
        .product_drop{
            margin-bottom: 50px;
            // width: 220px;
            span{
                font-size: 18px;
                margin-right: 15px;
            }
        }
        .Listimg_div{
            display: flex;
            flex-wrap: wrap;
            .Listimg_div-item{
                margin-right: 60px;
                margin-bottom: 50px;
                width: 220px;
                height: 375px;
                text-align: center;
                position: relative;
                overflow: hidden;
                &:hover .Listimg_div-modeBox{
                    bottom: 0;
                }
                .Listimg_div-new{
                    text-align: center;
                    span{
                        padding: 0 10px;
                        background-color: #ff4a2c;
                        color: #fff;
                        margin: auto;
                        border-radius: 5px;
                    }
                }
                img{
                    width: 220px;
                    height: 220px;
                }
                .Listimg_div-name{
                    margin-bottom: 10px;
                }
                .Listimg_div-modeBox{
                    width: 220px;
                    height: 160px;
                    background-color: hsla(0,0%,93.3%,1);
                    position: absolute;
                    bottom: -160px;
                    left: 0;
                    transition: all .6s;
                    .Listimg_div-modeBox-name,
                    .Listimg_div-modeBox-pic{
                        font-size: 18px;
                        margin-top: 5px;
                    }
                    .Listimg_div-modeBox-text{
                        font-size: 14px;
                        padding: 14px 14px 0;
                        color: #666;
                        display: -webkit-box;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                    }
                    .Listimg_div-modeBox-color{
                        text-align: center;
                        margin-top: 1px;
                        span{
                            display: inline-block;
                            width: 15px;
                            height: 15px;
                            border-radius: 50%;
                            margin: 0 3px;
                        }
                    }
                }
            }
        }
    }
}  
</style>