<template>
    <div class="Brand">
        <ConponentHeader></ConponentHeader>

        <div class="video">
            <video autoplay="true" loop src="../assets/video/1.mp4"></video>
			<div class="title">
				<p>Letsfit</p>
				<p>We're in this together.</p>
			</div>
        </div>
		<div class="container">
			<div class="Everyone">
				<div class="Everyone-left">
					<h1>
						Everyone has
					</h1>
					<h1>
						met challenges
					</h1>
					<h1>
						in life…
					</h1>
				</div>
				<div class="Everyone-right">
					<img src="../assets/images/g64.jpg" alt="">
				</div>
			</div>
			<div class="Many">
				Many people ask us why we started to do what we do.And our adventure into fitness began with a question, "How can we make our products accessible to as many people as possible so that we can be a part of giving people the confidence they need to do better?"
			</div>
			<div class="was">
				<div class="was-item was-item-one">
					<div class="was-item-header">
						It was here that this simple idea began.
					</div>
					<p>
						We didn't want the idea of fitness and good health to be just for professional athletes, or the fitness elite. But rather we believed that these things should be for everyone, coming in all different forms, for all different lifestyles. With that being said, we do not compromise on device quality, offering products that stand up to mother nature and even the most intense workouts.
					</p>
					<img class="was1" src="../assets/images/j1.jpg" alt="">
					<img class="was2" src="../assets/images/j2.jpg" alt="">
					<img class="was3" src="../assets/images/j3.jpg" alt="">
				</div>
				

			</div>
			<div class="Working">
				Working with people all around the world we listen to our customers the most.
			</div>
			<div class="Working-text">
				Working with people all around the world we listen to our customers the most and through exploration and wanting to give back to the community we make sure that we never forget the reason why we started this journey. Good Health should be shared..
			</div>
		</div>


      <ConponentFooter></ConponentFooter>  
    </div>
</template>

<script>
import ConponentHeader from '../components/header'
import ConponentFooter from '../components/footer'

export default {
    components:{
       ConponentHeader,
       ConponentFooter
    }
}
</script>

<style lang="scss" scoped>
	.video{
		width: 100%;
		margin-top: 70px;
		overflow: hidden;
		position: relative;
		.title{
			width: 100%;
			text-align: center;
			position: absolute;
			left: 50%;
			top: 38%;
			transform: translate(-50%);
			margin: auto;
			p{
				color: #fff;
				font-size: 100px;
				font-weight: 700;
			}
		}
	}
	.container{
		.Everyone{
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 116px 0 0 0;
			.Everyone-left{
				font-size: 32px;
				font-family: inherit!important;
				font-weight: 500;
				// width: 450px;
				// height: 380px;
				h1{
					font-weight: 100;
				}
			}
			.Everyone-right{
				img{
					width: 591px;
					height: 393px;
				}
			}
		}
		.Many{
			width: 600px;
			// margin: auto;
			margin: 50px 0 0 100px;
			font-size: 28px;
			line-height: 1.3;
		}
		.was{
			.was-item-one{
				height: 1237px;
				// border: 1px solid red;
			}
			.was-item{
				position: relative;
				.was-item-header{
					font-size: 62px;
					width: 674px;
					margin: 86px auto 85px auto;
					font-weight: 700;
					
				}
				p{
					width: 606px;
					font-size: 28px;
				}
				.was3{
					width: 316px;
					height: 468px;
					position: absolute;
					right: 0;
					top: 24.6%;
				}
				.was1{
					// position: absolute;
					// left: 0;
					// top: 694px;
					vertical-align: top;
					width: 316px;
					height: 387px;
					margin-top: 25px;
				}
				.was2{
					// position: absolute;
					// left: 0;
					// right: 0;
					// top: 744px;
					margin:60px 0 0 96px;
					width: 316px;
					height: 469px;
				}
			}
		}
		.Working{
			width: 1000px;
			margin: 0 auto ;
			font-size: 46px;
			font-weight: 700;
			padding-bottom: 31px;
		}
		.Working-text{
			font-size: 28px;
			padding-bottom: 114px;
			width: 1000px;
			margin: 0 auto ;
			font-weight: 300;
		}
	}
</style>