<template>
    <div class="warranty">
        <ConponentHeader></ConponentHeader>
        <div class="warranty-banner">
            <img src="../assets/images/95f5272.jpg" alt="">
        </div>
        <div class="container">
            <div class="Warranty_wrap_title"> Extend Your Warranty For 12 Months. </div>
            <div class="div2">
                <div class="warranty-item1">
                    <p>Name</p>
                    <input type="text">
                </div>
                <div class="warranty-item1">
                    <p>Email</p>
                    <input type="text">
                </div>
                <div class="warranty-item2">
                    <div class="warranty-item2-tab">
                        <span :class="{select:current == 'Amazon'}"  @click="selectTab('Amazon')">Amazon Order ID</span>
                        <span :class="{select:current == 'Indiegogo'}"  @click="selectTab('Indiegogo')">Indiegogo Pledge ID</span>
                        <input type="text" placeholder="xxx-xxx-xxx-xxx" v-if="current == 'Amazon'">
                        <input type="text" placeholder="xxxxxxxxx" v-if="current == 'Indiegogo'">
                    </div>
                </div>
                <div class="Warranty_checkbox">
                    <span class="show_agremenet" >
                         Terms & conditions
                        <div class="show_agremenet-text">
                            <ul>
                                <li>We will not share your personal information with any third parties.</li>
                                <li>Your purchase must be from a Letsfit authorized Amazon seller, or bought from Letsfit Official Amazon store.</li>
                                <li>We reserve the right to refuse for any stated reason upon direct request.</li>
                            </ul>
                        </div>
                    </span>
                    
                    <span class="checkbox-box" >
                        <span class="checkbox" @click="defind">
                            <span v-if="isdefind" class="icon iconfont">&#xe62f;</span>
                        </span>
                        <span>I agree</span>
                    </span>
                </div>
            </div>
            <div class="Warranty_fooder">
                <button type="button">Activate</button>
            </div>
            <div class="Warranty_here">
                <p class="noid" @click="toWarrantyWithout"> I don't have an order ID </p>
                <div class="ind-tip">
                    Getting Indiegogo  Pledge ID
                    <div class="ind-tip-text">
                        <ul>
                            <li>Please kindly check your pledge ID (8 digits)via your registered email address of Indiegogo.</li>
                        </ul>
                    </div>
                </div>
                <p class="next">Click <router-link to="/warrantyExplain">here</router-link> to view our Warranty Policy</p>
            </div>
        </div>
        <ConponentFooter></ConponentFooter>
    </div>
</template>
<script>
import ConponentHeader from '../components/header'
import ConponentFooter from '../components/footer'
export default {
    data(){
        return{
            current:'Amazon',
            isdefind:true
        }
    },
    methods:{
        selectTab(idx){
            this.current = idx
        },
        defind(){
            console.log(this.isdefind)

            this.isdefind = !this.isdefinds
        },
        toWarrantyWithout(){
            this.$router.push('/warrantyWithout')
        }
    },
    components:{
        ConponentHeader,
        ConponentFooter
    }   
}
</script>

<style lang="scss" scoped>
 .warranty{
     padding-top: 70px;
     .warranty-banner{
         img{
             max-width: 100%;
         }
     }
     .container{
         .Warranty_wrap_title{
             font-size: 50px;
            font-style: oblique;
            color: #222;
            padding-top: 80px;
            text-align: center;
         }
        .div2 {
             width: 404px;
             height: 377px;
             margin: 0 auto;
             .warranty-item1{
                 margin-top: 20px;
                 input{
                    box-sizing: border-box;
                    width: 100%;
                    height: 45px;
                    margin-top: 6px;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    padding: 0 20px;
                    outline: none;
                 }
                 p{
                     color: #999;
                 }
             }
             .warranty-item2{
                 margin-top: 20px;
                 .warranty-item2-tab{
                     span{
                        display: inline-block;
                        padding: 10px;
                        font-size: 16px;
                        color: #999;
                        transform: translateY(1px);
                        border: 1px solid rgba(0,0,0,0);
                        cursor: pointer;
                    }
                    .select{
                        border: 1px solid;
                        border-color: #ccc #ccc #fff;
                        border-top-left-radius: 4px;
                        border-top-right-radius: 4px;
                        color: #89c14c;
                    }
                 }
                 
                input{
                    box-sizing: border-box;
                    width: 100%;
                    height: 45px;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    border-top-left-radius: 0;
                    padding: 0 20px;
                    outline: none;
                    }
                    
                 }
         }
         .Warranty_checkbox{
            margin-top: 30px;
            font-size: 21px;
            cursor: pointer;
            font-weight: 200;
            position: relative;
            display: flex;
            .checkbox-box{
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .show_agremenet{
                position: relative;
                &:hover .show_agremenet-text{
                    display: block;
                }
                .show_agremenet-text{
                    width: 493px;
                    display: none;
                    // height: 176px;
                    position: absolute;
                    left: 0;
                    top: 30px;
                    background-color: #89c14c;
                    ul{
                        list-style-type: disc;
                        margin-left: 30px;
                        padding: 28px 10px;
                        li{
                            font-size: 16px;
                            color: #fff;
                            line-height: 1.5;
                        }
                    }
                }
            }
            .checkbox{
                width: 20px;
                height: 20px;
                display: inline-block;
                border: 1px solid #999;
                margin-left: 74px;
                border-radius: 4px;
                margin-right: 15px;
                line-height: 15px;
                text-align: center;
            }
         }
     }
     .Warranty_fooder{
            //  padding-top: 34px;
            text-align: center;
         button{
             cursor: pointer;
            border: none;
            font-size: 26px;
            color: #fff;
            height: 60px;
            padding-left: 46px;
            padding-right: 46px;
            background-color: #89c14c;
            border-radius: 30px;
         }
     }
     .Warranty_here{
         text-align: center;
         .noid{
                 margin: auto auto 20px;
                 margin-top: 20px;
                color: #89c14c;
                cursor: pointer;
         }
         .ind-tip{
             margin: auto auto 20px;
            cursor: pointer;
            color: #89c14c;
            position: relative;
            &:hover .ind-tip-text{
                display: block;   
            }
            .ind-tip-text{
                display: none;
                ul{
                    width: 404px;
                    position: absolute;
                    top: 24px;
                    left: 359px;
                    color: #fff;
                    padding: 28px 10px;
                    background-color: #89c14c;
                    text-align: left;
                    font-size: 16px;
                    line-height: 1.5;
                    z-index: 1;

                    li{
                        margin: 0;
                        padding: 0;
                        box-sizing: border-box;
                        border: 0;
                        font-size: 100%;
                        font: inherit;
                        vertical-align: baseline;
                        cursor: inherit;
                    }
                }
            }
         }
         .next{
             a{
                 text-decoration: none;
                 color: #89c14c;
             }
             margin-bottom: 35px;
         }
     }
 }   
</style>