<template>
    <div class="home">
        <ConponentHeader></ConponentHeader>
        <div class="swiper">
            <div @mouseover="stopSwiper" @mouseout="beginSwiper" :class=" {'swiperSelect':swiperCurrent == index,'swiper-item':true}" v-for="(item,index) in swiperData" :key="index"  :style="item">
                <div class="container">
 
                </div>
            </div>
        </div>
        <div class="container">
            <div class="box-0 clear">
                <router-link to="#" class="left" :style="boximg1"></router-link>
                <router-link to="#" class="right" :style="boximg2"></router-link>
            </div>

            <div class="box2">
                <router-link to="#" class="left" :style="boximg3">
                    <div class="box-item1-title">
                        <div class="box-item1-title-name">Get ready for the all new T20</div>
                        <div class="box-item1-title-text">Drop-Safe Fit TWS earbuds made for sport.</div>
                    </div>
                </router-link>
            </div>

            <div class="box3">
                <router-link to="#" v-for="(item,index) in box3" :key="index" class="box3-item" :style="item"></router-link>
            </div>
            
        </div>

        <div class="swiper2">
            <div class="container">
                <div class="img-content clear" ref="imgContent" >
                    <router-link to="#" v-for="(item,index) in swiper2" :key="index" :style="item.url"></router-link>
                </div>
            </div>    
        </div>

        <ConponentFooter></ConponentFooter>
    </div>
</template>

<script>
import ConponentHeader from '../components/header'
import ConponentFooter from '../components/footer'
export default {
    data(){
        return{
            swiperData:[
                {
                    background:'url(' + require('../assets/images/banner1.jpg') + ') 50% 100%',
                    width:this.clientWidth + "px"
                },
                {
                    background:'url(' + require('../assets/images/banner2.jpg') + ') 50% 100%',
                    width:this.clientWidth+ "px"
                },
                {
                    background:'url(' + require('../assets/images/banner3.jpg') + ') 50% 100%',
                    width:this.clientWidth+ "px"
                }
            ],
            swiper2:[
                {
                    url:{
                         background:'url(' + require('../assets/images/c1.jpg') + ')'
                    },
                },
                {
                    url:{
                         background:'url(' + require('../assets/images/c2.jpg') + ')'
                    },
                },
                {
                    url:{
                         background:'url(' + require('../assets/images/c3.jpg') + ')'
                    },
                },
                {
                    url:{
                         background:'url(' + require('../assets/images/c4.jpg') + ')'
                    },
                }
            ],
            boximg1:{"background-image":'url(' + require('../assets/images/p2.jpg') + ')'},
            boximg2:{"background-image":'url(' + require('../assets/images/p1.jpg') + ')'},
            boximg3:{"background-image":'url(' + require('../assets/images/ff.gif') + ')'},
            box3:[
                { "background-image":'url(' + require('../assets/images/b3.jpg') + ')'},
                { "background-image":'url(' + require('../assets/images/b2.jpg') + ')'},
                { "background-image":'url(' + require('../assets/images/b1.jpg') + ')'}
            ],
            swiperCurrent:0,
            clientWidth:null,
            time:null,
            Width:null,
            offset:0
  
        }  
        
    },
    created(){
        this.getCclientWidth()
        // this.width = 660*this.swiper2.length

    },
    mounted(){
        this.autoSwiper()
        this.$refs.imgContent.style.width = (1149*this.swiper2.length) + (this.swiper2.length*10) + 'px'
        // if(this.swiper2.length == 1){
        //     this.$refs.imgContent.style.left = "-5px"
        // }

    },
    methods:{
        autoSwiper(){
           this.time =  setInterval(()=>{
                var length = this.swiperData.length-1
                if(this.swiperCurrent == length){
                    this.swiperCurrent = 0
                }else{
                    this.swiperCurrent++
                }
            },1200)
        },
        getCclientWidth(){
            var width = document.documentElement.clientWidth

            addEventListener("resize",()=>{
                 width = document.documentElement.clientWidth
            })
            if(width <= 1148) width = 1148
            this.clientWidth = width

        },
        stopSwiper(){
            clearInterval(this.time)
        },
        beginSwiper(){
            this.autoSwiper()
        }
    },
   components:{
       ConponentHeader,
       ConponentFooter
   } 
}
</script>

<style lang="scss" scoped>
.swiper{
    margin-top: 70px;
        height: 800px;
    .container{
        height: 800px;
    }
    position: relative;
    overflow: hidden;
    .swiper-item{
        height: 800px;
        position: absolute;
        z-index: -1;
        opacity: 0; 
        width: 100%;
        height: 100%;
        transition: all 1s;

        img{
            max-width: 100%;
            height: 100%;
        }
    }
    .swiperSelect{
        z-index: 1;
        opacity: 1;
        
    }
}
.container{
    .box-0{
        width: 100%;
        margin-top: 40px;
        overflow: hidden;
        border-radius: 20px;
        .left,
        .right{
            float: left;
            transition: background-size .3s ease-out 0s;
            height: 500px;
            background-color: #f3f3f3;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: 81%;
            color: #fff;
            &:hover{
                 background-size: 108% 108%;
            }
        }
        .left{
            width: 376px;
        }
        .right{
            width: 762px;
            margin-left: 10px;
        }
    }
    .box2{
        width: 100%;
        margin-top: 40px;
        overflow: hidden;
        border-radius: 20px;
        // border: 1px solid red;
        a{
            text-decoration: none;
            display: inline-block;
            width: 100%;
            height: 500px;
            background-color: #f3f3f3;
            background-size: 108% 108%;
            background-repeat: no-repeat;
            background-position: 81%;
            color: #fff;
            -webkit-transition: background-size .3s ease-out 0s;
            transition: background-size .3s ease-out 0s;
            .box-item1-title{
                padding: 0 30px;
                .box-item1-title-name{
                    font-size: 32px;
                    position: relative;
                    top: auto;
                    margin-top: 310px;
                }
                .box-item1-title-text{
                        margin-top: 10px;
                }
            }
        }
    }
    .box3{
        width: 100%;
        margin-top: 40px;
        overflow: hidden;
        border-radius: 20px;
        a{
            display: inline-block;
            width: 376px;
            height: 500px;
            background-color: #f3f3f3;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: 50%;
            color: #fff;
            -webkit-transition: background-size .3s ease-out 0s;
            transition: background-size .3s ease-out 0s;
        }
        a:hover{
            background-size: 108% 108%;
        }
        a:nth-child(2){
            margin: 0 10px;

        }
    }
}
.swiper2{
    overflow: hidden;
    margin: 40px auto 40px auto;
    .container{
        height: 660px;
        border: 1px solid red;
        position: relative;
        .img-content{
            // width: 1148px;
            position: absolute;
            left: -1159px;
        }
        a{
            height: 660px;
            width: 1148px;
            float: left;
            display: inline-block;
        // border: 1px solid red;
            background-size: 100%;
            margin-right: 5px;
        }
    }
}
</style>