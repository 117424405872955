<template>
    <div class="productInfo">
    <ConponentHeader></ConponentHeader>
    <div class="navTop">
        <div class="prod-name">EW1</div>
        <router-link to="#">
            Buy Now
        </router-link>
    </div>
    <div class="productInfo-main">
        <img src="../assets/images/ff132.jpg" alt="">
    </div>

    <ConponentFooter></ConponentFooter>
    </div>
</template>
<script>
import ConponentHeader from '../components/header'
import ConponentFooter from '../components/footer'
export default {
    data(){
        return{
            showTop:false
        }
    },
    mounted(){
        this.addEvent()
    },
    methods:{
        addEvent(){
            addEventListener('scroll',()=>{
                var topWidth = document.documentElement.scrollTop
                if(topWidth > 10){
                    this.showTop = true
                }else{
                    this.showTop = false
                }
                console.log(this.showTop)
            })
        }
    },
    components:{
            ConponentHeader,
            ConponentFooter
        }     
}
</script>

<style lang="scss" scoped>
.productInfo{
    padding-top: 70px;
    .productInfo-main{
        img{
            width: 100%;
            height: 100%;
        }
    }
    .navTop{
        height: 70px;
        border: 1px solid red;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background-color: #fff;
        z-index: 999999;
        display: flex;
        justify-content: space-between;
        a{
            margin-top: 16px;
            padding: 10px 14px;
            font-size: 14px;
            color: #fff;
            background-color: #72bb4a;
            border-radius: 17px;
            text-decoration: none;
        }
    }
} 
</style>