import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home.vue'
import Brand from '../views/Brand.vue'
import Community from '../views/Community.vue'
import SeedUser from '../views/SeedUser.vue'
import warrantyExplain from '../views/warrantyExplain.vue'
import warranty from '../views/warranty.vue'
import warrantyWithout from '../views/warrantyWithout.vue'
import productList from "../views/productList.vue"
import FitnessWearables from '../views/FitnessWearables.vue'
import search from '../views/search.vue'
import productInfo from '../views/productInfo.vue'

Vue.use(VueRouter)

const routes = [
	{
		path:"/productInfo",
		name:productInfo,
		component:productInfo
	  },
	{
		path:"/search",
		name:search,
		component:search
	  },
    {
      path:"/",
      name:home,
      component:home
    },
    {
      path:"/Brand",
      name:Brand,
      component:Brand
    },
	{
	  path:"/Community",
	  name:Community,
	  component:Community
	},
	{
	  path:"/SeedUser",
	  name:SeedUser,
	  component:SeedUser
	},
  {
	  path:"/warrantyExplain",
	  name:warrantyExplain,
	  component:warrantyExplain
	},
  {
	  path:"/warranty",
	  name:warranty,
	  component:warranty
	},
  {
	  path:"/warrantyWithout",
	  name:warrantyWithout,
	  component:warrantyWithout
	},
  {
	  path:"/productList",
	  name:productList,
	  component:productList
	},
	{
		path:"/FitnessWearables",
		name:FitnessWearables,
		component:FitnessWearables
	  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
