<template>
	<div class="SeedUser">
		<ConponentHeader></ConponentHeader>
		
		<div class="container">
			
			<div class="SeedUser-content">
				<div class="SeedUser-step-box">
					<div class="SeedUser-step">
						<el-steps  finish-status="success">
						  <el-step ></el-step>
						  <el-step ></el-step>
						  <el-step ></el-step>
						</el-steps>
					</div>
				</div>
				
				<div class="SeedUser-main">
					<div class="SeedUser-title"> Inspire, Share, Get Products. </div>
					<div class="SeedUser-text">Your opinion matters to us, select the device below you wish to experience and help us improve our products,  <br> give feedback and get your product for free.</div>
					<div class="view-btn" @click="onViewRules">
						View Rules
					</div>
				</div>
				
			</div>
			
			<div class="sd-sp-btn">
				<button @click="showNext">Next</button>
			</div>
			
		</div>
		
		
		<div class="modalBox" v-show="showView">
			<div class="sd-hd-rules-dialog-ct">
				<span class="icon iconfont" @click="closeViewRules">&#xe672;</span>
				<div class="sd-hd-rules-dialog-ct-title">View Rules</div>
				<div class="modalBox-text">
				<ul type="1">
									<li> This promotion is only available for Letsfit registered users. </li>
									<li>  To get the opportunity to take part in free sample evaluations and testing, you will need to provide a shipping address, that we will use only to send samples to you. </li>
									<li>  To sign up for this promotion, we may ask you some questions about products or sports habits that you may have to evaluate before you can participate any specific product evaluation. </li>
									<li>  Users can check whether their application is successful by viewing their user center details - my applications.  </li>
									<li>  This promotion aims to get real feedback from real users by sending free samples so that we can provide better products and services. Your evaluation content is very important to us and we ask that you please leave feedback. </li>
									<li> This promotion is only available to users in based in the United States of America. </li>
									<li>  This promotion is limited to 15 people. Promotion will end on the 23rd Sep 2020. If you are chosen to be part of this promotion you will receive an email on the 25th Sep 2020 confirming enrollment and your product will be sent approximately 2 weeks after. </li>
									<li>  Testing club products can use our regular aftersales service but are not available for the extended warranty program. </li>
							
								</ul>
				</div>
			</div>
		</div>
		<div class="NextModalBox" v-if="NextShow">
			<div class="NextModalBoxcontent" >
				<span class="icon iconfont conle" @click="cloneNext">&#xe672;</span>
				<img src="../assets/images/63c6871.png" alt="">
				<div class="end-title">
					<div>Oh no! Looks like we ran out of time.</div>
					<div>Our promotion has now ended. </div>
				</div>
				<div class="end-desc">
                    If you'd like to be the first to find out about new promotions you can sign up for email alerts 
					</div>
					<div class="end-desc2">or visit</div>
					<div class="end-icon">
						 <span class="icon iconfont">&#xe684;</span>
						  <span class="icon iconfont">&#xe6b6;</span>
					</div>
			</div>
		</div>
		<ConponentFooter></ConponentFooter>
		  
	</div>
</template>

<script>
import ConponentHeader from '../components/header'
import ConponentFooter from '../components/footer'
export default{
	data(){
		return{
			showView:false,
			NextShow:false
		}
	},
	methods:{
		onViewRules(){
			this.showView = true
		},
		closeViewRules(){
			this.showView = false
		},
		showNext(){
			this.NextShow = true
		},
		cloneNext(){
			this.NextShow = false
		}
	},
	components:{
		ConponentHeader,
		ConponentFooter
	}
}
</script>

<style scoped lang="scss">
.SeedUser{
	.SeedUser-content{
		overflow: hidden;
	}
	background-color: #F4F4F5;
	.container{
		margin-top: 70px;
		padding-top: 15px;
		// border: 1px solid red;
		.SeedUser-step-box{
			width: 100%;
			padding: 12px 0;
			background-color: #fff;
			border-bottom: 1px solid #eee;
			// border: 1px solid transparent;
			.SeedUser-step{
				width: 376px;
				height: 32px;
				margin: 0 auto;
				margin-top: 35px;
			}
		}
		.SeedUser-main{
			height: 171px;
			text-align: center;
			background-color: #FFFFFF;
			overflow: hidden;
			.SeedUser-title{
				height: 35px;
				margin-top: 20px;
				font-weight: 700;
				font-size: 28px;
				color: #404040;
				text-align: center;
				overflow: hidden;
				
			}
			.SeedUser-text{
				margin-top: 10px;
				font-size: 18px;
				color: #999;
				line-height: 1.2;
				text-align: center;
				overflow: hidden;
				
			}
			.view-btn{
				color: #72bb4a;
				text-align: center;
				margin-top: 20px;
				font-size: 18px;
				color: #72bb4a;
				text-align: center;
				cursor: pointer;
			}
		}
		.sd-sp-btn{
			overflow: hidden;
			padding-top: 60px;
			padding-bottom: 40px;
			text-align: center;
			background-color: #fff;
			margin-top: 15px;
			button{
				width: 404px;
				height: 52px;
				background: #c7e18f;
				font-size: 24px;
				color: #fff;
				border: none;
				border-radius: 52px;
				cursor: pointer;
			}
		}
		
	}
}
.modalBox{
	width: 100%;
	height: 100vh;
	background-color: rgba(0,0,0,.5);
	position: fixed;
	left: 0;
	top: 0px;
	z-index: 9999;
	.sd-hd-rules-dialog-ct{
		position: absolute;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%,-50%);
		-ms-transform: translate(-50%,-50%);
		transform: translate(-50%,-50%);
		width: 800px;
		padding: 40px 70px;
		background-color: #fff;
		border-radius: 5px;
		.icon{
			position: absolute;
			top: 10px;
			right: 10px;
			font-size: 26px;
			cursor: pointer;
		}
		.sd-hd-rules-dialog-ct-title{
			font-size: 32px;
			color: #72bb4a;
		}
		.modalBox-text{

			li{
				color: #404040;
				margin-top: 10px;
			}
		}
		
	}
}
.NextModalBox{
	position: fixed;
	z-index: 999;
	top: 70px;
	right: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,.5);
	.NextModalBoxcontent{
		position: absolute;
		top: 50%;
		left: 50%;
		width: 930px;
		height: 417px;
		-webkit-transform: translate(-50%,-50%);
		-ms-transform: translate(-50%,-50%);
		transform: translate(-50%,-50%);
		background-color: #fff;
		padding: 20px;
		border-radius: 5px;
		color: #404040;
		text-align: center;
		.conle{
			position: absolute;
			right: 10px;
			top: 10px;
			font-size: 25px;
			cursor: pointer;
		}
		.end-title{
			font-size: 28px;
		}
		.end-desc{
			font-size: 18px;
			margin-top: 19px;
			font-weight: 200;
		}
		.end-desc2{
			font-size: 20px;
			margin-top: 80px;
			text-align: center;
		}
		.end-icon{
			margin-top: 20px;
			color: #89c14c;
			text-align: center;
			span{
				font-size: 25px;
				margin: 0 10px;
			}
		}
	}
}
</style>
